var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "@emotion/styled";
var ErrorMessage = styled.pre(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: red;\n"], ["\n  color: red;\n"])));
function Fallback(_a) {
    var error = _a.error;
    return (React.createElement("div", { role: "alert" },
        React.createElement("p", null, "Something went wrong:"),
        React.createElement(ErrorMessage, null, error.message)));
}
export default Fallback;
var templateObject_1;
