import fetchMock from "fetch-mock";
import { LoremIpsum } from "lorem-ipsum";
var lorem = new LoremIpsum({
    sentencesPerParagraph: {
        max: 8,
        min: 4,
    },
    wordsPerSentence: {
        max: 16,
        min: 4,
    },
});
export var initMocks = function (host) {
    fetchMock.mock(new RegExp("".concat(host, "/api/v1/stage.*")), [
        {
            name: "mocked-stage-name",
        },
        {
            name: "mocked-stage-name2",
        },
        {
            name: "mocked-stage-name3",
        },
    ]);
    fetchMock.get("".concat(host, "/api/v1/agent_flows/?flow_id=FlowId"), {
        name: "Mocked flow name",
        id: "Mocker flow id",
        ver: "2",
    });
    fetchMock.mock(new RegExp("".concat(host, "/api/v1/agent_flows/mockstagename.*")), [
        {
            name: "mocked-flow-name",
            id: 1,
        },
    ]);
    fetchMock.mock(new RegExp("".concat(host, "/api/v1/agent_flows/")), [
        {
            name: "mocked-flow-name",
            id: 1,
        },
    ]);
    fetchMock.mock(new RegExp("".concat(host, "/api/v1/custom_workflow_buttons.*")), [
        {
            name: "mocked-custom-button",
        },
    ]);
    fetchMock.post("".concat(host, "/api/v1/conversations/"), {
        id: 100,
        user_id: "userId",
        df_account_id: "dfAccountId",
        flow_id: "FlowId",
        ml_model: "mlModelId",
        prev_state: "",
        new_state: "",
        num_messages: 1,
        time_created: Date.now() / 1000,
    });
    fetchMock.post("".concat(host, "/api/v1/messages/"), function (_, opts) {
        var _a;
        var body = JSON.parse(((_a = opts.body) === null || _a === void 0 ? void 0 : _a.toString()) || "");
        return [
            {
                id: (Math.random() + 1).toString(36).substring(7),
                conversation_id: 100,
                body: body[0].body,
                current_stage: body[0].new_state || "newstatemock",
                role: body[0].role,
                tone: body[0].tone,
                is_edited: false,
                raw_gen_message_id: "rawId",
                time_created: Date.now() / 1000,
            },
        ];
    }, { delay: 3000 });
    fetchMock.get("".concat(host, "/api/v1/messages/?conversation_id=100"), [
        {
            id: 1,
            conversation_id: 100,
            body: lorem.generateParagraphs(2),
            current_stage: "newstatemock",
            role: "bot",
            is_edited: false,
            prompt_content: "prompt_" + lorem.generateParagraphs(2),
            raw_gen_message_id: 4,
            time_created: Date.now() / 1000,
        },
        {
            id: 2,
            conversation_id: 100,
            body: lorem.generateParagraphs(2),
            current_stage: "newstatemock",
            role: "bot",
            is_edited: false,
            prompt_content: "prompt_" + lorem.generateParagraphs(2),
            raw_gen_message_id: 3,
            time_created: Date.now() / 1000,
            feedback: {
                feedback: "Previous feedback MOCK",
            },
        },
        {
            id: 3,
            conversation_id: 100,
            body: lorem.generateParagraphs(2),
            current_stage: "newstatemock",
            role: "user",
            is_edited: false,
            prompt_content: "prompt_" + lorem.generateParagraphs(2),
            raw_gen_message_id: 3,
            time_created: Date.now() / 1000,
        },
    ]);
    fetchMock.get("".concat(host, "/api/v1/df_accounts/?df_case_number=dfAccountId"), {
        id: 20,
        name: "DF merchant",
        df_case_number: "dfAccountId",
        phone_number: "0000000000",
        current_status: "mockStatus",
        df_internal_id: "DFInternalId",
        time_created: 0,
        time_received: 0,
    });
    fetchMock.get("".concat(host, "/api/v1/df_accounts/?df_case_number=2020-5214"), {
        id: 20,
        name: "DF merchant",
        df_case_number: "2020-5214",
        phone_number: "0000000000",
        current_status: "mockStatus",
        df_internal_id: "DFInternalId",
        time_created: 0,
        time_received: 0,
    });
    fetchMock.get("".concat(host, "/api/v1/conversations/?conversation_id=100"), {
        id: 100,
        user_id: 1,
        df_account_id: "dfAccountId",
        flow_id: "FlowId",
        ml_model: "mlModelId",
        current_stage_info: {
            name: "mockstagename",
            count: 1,
        },
        token_count: {
            inp_token_count: 1,
            out_token_count: 2,
        },
        num_messages: 1,
        time_created: Date.now() / 1000,
    });
    fetchMock.put("".concat(host, "/api/v1/conversations/100"), function (_, opts) {
        var _a;
        var body = JSON.parse(((_a = opts.body) === null || _a === void 0 ? void 0 : _a.toString()) || "");
        return {
            id: 100,
            user_id: "userId",
            df_account_id: "dfAccountId",
            flow_id: "FlowId",
            ml_model: "mlModelId",
            current_stage_info: {
                new_state: body.current_stage || "unknown",
                count: 1,
            },
            token_count: {
                inp_token_count: 1,
                out_token_count: 2,
            },
            num_messages: 1,
            time_created: Date.now() / 1000,
        };
    });
    fetchMock.get("".concat(host, "/api/v1/conversations/"), [
        {
            id: 100,
            user_id: "userId",
            df_account_id: "dfAccountId",
            flow_id: "FlowId",
            ml_model: "mlModelId",
            current_stage_info: {
                name: "mockstagename",
                count: 1,
            },
            token_count: {
                inp_token_count: 1,
                out_token_count: 2,
            },
            num_messages: 1,
            time_created: Date.now() / 1000,
        },
    ], { delay: 3000 });
    fetchMock.get("".concat(host, "/api/v1/users/?list_all=false"), {
        id: 1,
        email: "mock@email.com",
        name: "John Do",
        customer_id: "mock-customer-id",
        phone_number: "0000000000",
    });
    fetchMock.get("".concat(host, "/api/v1/generated_messages/messageId"), {
        id: (Math.random() + 1).toString(36).substring(7),
        conversation_id: "",
        body: lorem.generateParagraphs(2),
        current_stage: "intro_contact",
        // tone: "Tonemock",
    });
    fetchMock.get("".concat(host, "/api/v1/generated_messages/?type=2"), [
        {
            id: (Math.random() + 1).toString(36).substring(7),
            conversation_id: "",
            body: lorem.generateParagraphs(1),
            current_stage: "intro_contact",
            type: 2,
            tone: "Tonemock",
        },
        {
            id: (Math.random() + 1).toString(36).substring(7),
            conversation_id: "",
            body: lorem.generateParagraphs(1),
            current_stage: "intro_contact",
            type: 2,
            tone: "Tonemock",
        },
        {
            id: (Math.random() + 1).toString(36).substring(7),
            conversation_id: "",
            body: lorem.generateParagraphs(1),
            current_stage: "intro_contact",
            type: 2,
            tone: "Tonemock",
        },
        {
            id: (Math.random() + 1).toString(36).substring(7),
            conversation_id: "",
            body: lorem.generateParagraphs(1),
            current_stage: "intro_contact",
            type: 2,
            tone: "Tonemock",
        },
        {
            id: (Math.random() + 1).toString(36).substring(7),
            conversation_id: "",
            body: lorem.generateParagraphs(1),
            current_stage: "intro_contact",
            type: 2,
            tone: "Tonemock",
        },
        {
            id: (Math.random() + 1).toString(36).substring(7),
            conversation_id: "",
            body: lorem.generateParagraphs(1),
            current_stage: "intro_contact",
            type: 2,
            tone: "Tonemock",
        },
        {
            id: (Math.random() + 1).toString(36).substring(7),
            conversation_id: "",
            body: lorem.generateParagraphs(1),
            current_stage: "intro_contact",
            type: 2,
            tone: "Tonemock",
        },
        {
            id: (Math.random() + 1).toString(36).substring(7),
            conversation_id: "",
            body: lorem.generateParagraphs(1),
            current_stage: "intro_contact",
            type: 2,
            tone: "Tonemock",
        },
    ]);
    fetchMock.get("".concat(host, "/api/v1/prompts/mockstagename/FlowId"), {
        id: (Math.random() + 1).toString(36).substring(7),
        name: "mock_prompt",
        ver: "mock_version",
        body: "".concat(lorem.generateParagraphs(1), "\n\n").concat(lorem.generateParagraphs(1), "\n").concat(lorem.generateParagraphs(1), "\n"),
        data: {
            foo: "bar",
        },
        time_created: Date.now() / 1000,
    });
    /*
    id: string;
    conversation_id: string;
    body: string;
    prev_state: string;
    new_state: string;
    tone: string;
    */
    fetchMock.post("".concat(host, "/api/v1/generated_messages/"), function (_, opts) {
        var _a;
        var body = JSON.parse(((_a = opts.body) === null || _a === void 0 ? void 0 : _a.toString()) || "");
        return {
            id: (Math.random() + 1).toString(36).substring(7),
            conversation_id: body.conversationId,
            body: lorem.generateParagraphs(2),
            current_stage: "intro_contact",
            prompt_content: "PROMPT_" + lorem.generateParagraphs(2),
            // tone: body?.tone,
        };
    }, { delay: 1000 });
    fetchMock.get("".concat(host, "/api/v1/models/?default_only=0"), [
        {
            id: 1,
            model_name: "Mock model name",
        },
    ]);
    // Do not mock hot reloader request
    fetchMock.spy(/.*hot-update\.json/);
};
export var restore = function () {
    fetchMock.restore();
};
