var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ApiError, getLocalStorageAdapter, initClient, initLogger, } from "@saris-ai/sdk";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType, } from "react-router-dom";
import Fallback from "@components/Fallback";
import Updater from "./pages/Updater";
import { API_URL } from "./utils/api";
import "./index.scss";
// https://docs.sentry.io/platforms/javascript/guides/react/
Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.VERSION,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation: useLocation,
            useNavigationType: useNavigationType,
            createRoutesFromChildren: createRoutesFromChildren,
            matchRoutes: matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [new RegExp("^".concat(process.env.API_URL))],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    enabled: process.env.NODE_ENV === "production", // Disable sentry in dev/test mode
});
if (process.env.SENTRY_DSN && process.env.VERSION) {
    initLogger(process.env.SENTRY_DSN, process.env.VERSION);
}
// https://mui.com/material-ui/customization/theming/
var defaultTheme = createTheme();
// https://tanstack.com/query/v3/docs/react/quick-start
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retryOnMount: true,
            retryDelay: function (attemptIndex) { return Math.min(1500 * Math.pow(2, attemptIndex), 30000); },
            retry: function (errorCount, error) {
                return (errorCount < 3 &&
                    error instanceof ApiError &&
                    (error.status === 429 || error.status === 500));
            },
            // Stale data for 10 min
            staleTime: 1000 * 60 * 10,
        },
    },
});
initClient(API_URL, {
    localStorageCustomPrefixKey: "",
    storageAdapter: getLocalStorageAdapter(),
});
var Router = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import("./pages/Router")];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); });
var root = document.getElementById("root");
if (root !== null) {
    var domNode = createRoot(root);
    domNode.render(React.createElement(React.StrictMode, null,
        React.createElement(Sentry.ErrorBoundary, { fallback: function (err) { return React.createElement(Fallback, { error: err.error }); } },
            React.createElement(BrowserRouter, null,
                React.createElement(ThemeProvider, { theme: defaultTheme },
                    React.createElement(QueryClientProvider, { client: queryClient },
                        React.createElement(Updater, null),
                        React.createElement(Router, null)))))));
}
